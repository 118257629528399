import React from 'react';
import './About.css';

const About = () => {
    return (
        <div class="about-section" id="about">
            <h1>O Nama</h1>
            <p>Firma Alio doo je osnovana 2020 godine u Sarajevu.
            Mi smo mala porodična firma sa željom i voljom za razvijanjem poslovanja. Otvoreni smo za saradnju i sa velikim i malim firmama.
            </p>
            <p>Naša svakodnevna pokretačka snaga je dječak Ali po kojem je firma i dobila naziv. Njegovo pitanje "hoćemo li ovim putem danas" i "nema problema, mi to možemo" je naš izvor energije.</p>
            <p>Osnovna djelatnost našeg poduzeća je trgovina na veliko proizvodima široke potrošnje.
            Svaki član našeg malog tima je samostalan i samostalno donosi odluke. Potičemo ih da učeći iz svakodnevnih izazova razvijaju sebe i firmu. 
            </p>
            <p>Ukoliko imate pitanje, savjet ili prijedlog za saradnju, kontaktirajte nas putem kontakt obrasca, e-maila ili telefona.</p>
        </div>
    )
}

export default About;