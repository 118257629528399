import React from 'react';
import './News.css';
import Picture5 from './images/pic5.jpg';
import Picture6 from './images/pic6.jpg';
import Picture7 from './images/pic7.jpg';
import Picture8 from './images/pic8.jpg';
import Picture9 from './images/pic9.jpg';
import Picture10 from './images/pic10.jpg';
import Picture28 from './images/pic28_cropped.jpg';

const News = () => {
    return (
        <div id="news">
            <h1 class="header-text">Novosti iz Alio Doo</h1>

            <div class="news-box-wide">
                <div class="news-img">
                    <img src={Picture28}/>
                </div>
                <div class="news-text">
                    <p class="card-text news-card-text">Od sada profesionalni proizvodi za njegu kose "Argan Oil" <p class="news-text-bold">u svim dm marketima</p> širom BiH</p>
                    </div>
                </div>
            <div class="clearfix"></div>

            <div class="card news-box-narrow">
                <img src={Picture28} class="card-img-top" alt="..." />
                <div class="card-body">
                    <p class="card-text news-card-text-narrow">Od sada profesionalni proizvodi za njegu kose "Argan Oil" <p class="news-text-bold-narrow">u svim dm marketima</p> širom BiH</p>
                </div>
            </div>
                
        </div>
    )
}

export default News;