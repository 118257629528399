import React from 'react';
import { ReactComponent as YourSvg } from './images/header.svg';
import './Header.css';
import Video from './images/commercial.mp4';
{/*import { ReactComponent as RandomSVG1 } from './images/circle1.svg';
import { ReactComponent as RandomSVG2 } from './images/circle2.svg';
import { ReactComponent as RandomSVG3 } from './images/circle3.svg';
import { ReactComponent as RandomSVG4 } from './images/circle4.svg';
import { ReactComponent as RandomSVG5 } from './images/circle5.svg';
import { ReactComponent as RandomSVG6 } from './images/circle6.svg';
import { ReactComponent as RandomSVG7 } from './images/circle7.svg';
import { ReactComponent as RandomSVG8 } from './images/circle8.svg';*/}

const Header = () => {
    return (
        <div class="header" id="header">
            <YourSvg />
            <div class="video">
                <video controls loop="true" autoplay="autoplay" muted>
                    <source src={Video} type="video/mp4"/>
                </video>
            </div>

            {/*<div class="random-svg1">
                <RandomSVG1 />
            </div>
            <div class="random-svg2">
                <RandomSVG2 />
            </div>
            <div class="random-svg3">
                <RandomSVG3 />
            </div>
            <div class="random-svg4">
                <RandomSVG4 />
            </div>
            <div class="random-svg5">
                <RandomSVG5 />
            </div>
            <div class="random-svg6">
                <RandomSVG6 />
            </div>
            <div class="random-svg7">
                <RandomSVG7 />
            </div>
            <div class="random-svg8">
                <RandomSVG8 />
            </div>*/}

        </div>
    )
}

export default Header;