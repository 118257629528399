import React from 'react';
import './Navbar.css';
import logo from './images/logo.jpg';
import Fblogo from './images/fb_logo.svg';
import Iglogo from './images/ig_logo.svg';
import Ytlogo from './images/yt_logo.svg';
import { Link } from 'react-scroll';
import { animateScroll as scroll } from 'react-scroll';

const Navbar = () => {
    return (
        <nav class="navbar navbar-expand-lg navbar-light bg-light sticky-top" id="navbar">
            <div class="container-fluid">
                {/*<a class="navbar-brand" href="#">Navbar</a>*/}
                <img id="main-logo" src={logo} alt="logo" />
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div class="navbar-nav pt-3 pb-3">
                    <a class="collapse-logo" href="#"><img id="main-logo-collapse" src={logo} alt="logo" /></a>
                    <a class="nav-link active" aria-current="page" href="#">Početna</a>
                    <Link class="nav-link active" to="about" smooth={true} duration={100}>O Nama</Link>
                    <Link class="nav-link active" to="news" smooth={true} duration={100}>Novosti</Link>
                        
                    {/*<a class="nav-link active" href="#">Brendovi I Proizvodi</a>
                    <a class="nav-link active" href="#">Kontakt</a>*/}
                        
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Brendovi I Proizvodi
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li><Link class="dropdown-item" to="cards" smooth={true} duration={100}>Argan Oil</Link></li>
                            <li><a class="dropdown-item" href="#">ELA</a></li>
                        </ul>
                    </li>
                        
                        <Link class="nav-link active" to="contact" smooth={true} duration={100}>Kontakt</Link>
                        
                        <a href="https://www.facebook.com/arganoil.bih/" target="_blank"><img id="fblogo" src={Fblogo} alt="Fb logo" /></a>
                        <a href="https://www.instagram.com/arganoil.bih/" target="_blank"><img id="iglogo" src={Iglogo} alt="Ig logo" /></a>
                        <a href="#"><img id="ytlogo" src={Ytlogo} alt="Yt logo" /></a>
                        
                    {/*<li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Izaberite Jezik
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li><a class="dropdown-item" href="#">Bosanski</a></li>
                            <li><a class="dropdown-item" href="#">Engleski</a></li>
                        </ul>
                    </li>*/}
                    </div>
                    {/*<div class="logo-div">
                        <a href="#"><img id="fblogo" src={Fblogo} alt="Fb logo" /></a>
                        <a href="#"><img id="iglogo" src={Iglogo} alt="Ig logo" /></a>
                        <a href="#"><img id="ytlogo" src={Ytlogo} alt="Yt logo" /></a>
                </div>*/}
                </div>
            </div>
        </nav>
    )
}

export default Navbar;