import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div class="contact-section" id="contact">
            <h1>Kontakt</h1>
            <p>Ukoliko imate pitanja vezana za naše proizvode ili brandove rado ćemo Vam pomoći.
            Javite nam se na telefon + 387 61 863 055  i  + 387 61 489 444 ili putem kontakt obrasca.
            </p>
            <p>Svaki radni dan od 8:00 do 16:00 sati ili putem kontakt obrasca u bilo koje doba odgovorit ćemo vam u najkraćem roku!</p>
            <p>Alio d.o.o., Džamijska 7</p>
            <p>71 000 Sarajevo, Tel. : + 387 61 863 055;  + 387 61 489 444 , E-mail: aliodoo3@gmail.com</p>
        </div>
    )
}

export default Contact;