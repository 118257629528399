import React from 'react';
import './Cards.css';
import Picture1 from './images/pic1.jpg';
import Picture2 from './images/pic2.jpg';
import Picture3 from './images/pic3.jpg';
import Picture4 from './images/pic4.jpg';
import Picture5 from './images/pic5.jpg';
import Picture6 from './images/pic6.jpg';
import Picture7 from './images/pic7.jpg';
import Picture8 from './images/pic8.jpg';
import Picture9 from './images/pic9.jpg';
import Picture10 from './images/pic10.jpg';
import Picture11 from './images/pic11.jpg';
import Picture12 from './images/pic12.jpg';
import Picture13 from './images/pic13.jpg';
import Picture17 from './images/pic17.jpg';
import Picture18 from './images/pic18.jpg';
import Picture19 from './images/pic19.jpg';
import Picture21 from './images/pic21.jpg';

const Cards = () => {
    return (
        <div id="cards">
            <h1 class="product-text">Proizvodi</h1>
            <div className="product-cards">

                <div class="card" style={{width: "18rem"}}>
                    <img src={Picture3} class="card-img-top" alt="..."/>
                    <div class="card-body">
                        <p class="card-text">Argan Oil | Hair Treatment Oil</p>
                    </div>
                </div>

                <div class="card" style={{width: "18rem"}}>
                    <img src={Picture12} class="card-img-top" alt="..."/>
                    <div class="card-body">
                        <p class="card-text">Nourish & Smooth Shampoo | New Formula</p>
                    </div>
                </div>

                <div class="card" style={{width: "18rem"}}>
                    <img src={Picture11} class="card-img-top" alt="..."/>
                    <div class="card-body">
                        <p class="card-text">Deep Restorative Hair Mask With Argan Oil & Keratin</p>
                    </div>
                </div>

                <div class="card" style={{width: "18rem"}}>
                    <img src={Picture1} class="card-img-top" alt="..."/>
                    <div class="card-body">
                        <p class="card-text">Anti Hair Loss Shampoo | For Women & Men</p>
                    </div>
                </div>

            </div>

            <div class="products-img">
                <img src={Picture13}/>
            </div>

            <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active" data-bs-interval="4000">
                        <img src={Picture5} class="d-block w-100" alt="..."/>
                    </div>
                    <div class="carousel-item" data-bs-interval="4000">
                        <img src={Picture17} class="d-block w-100" alt="..."/>
                    </div>
                    <div class="carousel-item" data-bs-interval="4000">
                        <img src={Picture18} class="d-block w-100" alt="..."/>
                    </div>
                    <div class="carousel-item" data-bs-interval="4000">
                        <img src={Picture6} class="d-block w-100" alt="..."/>
                    </div>
                    <div class="carousel-item" data-bs-interval="4000">
                        <img src={Picture19} class="d-block w-100" alt="..."/>
                    </div>
                    <div class="carousel-item" data-bs-interval="4000">
                        <img src={Picture21} class="d-block w-100" alt="..."/>
                    </div>
                    <div class="carousel-item" data-bs-interval="4000">
                        <img src={Picture7} class="d-block w-100" alt="..."/>
                    </div>
                    <div class="carousel-item" data-bs-interval="4000">
                        <img src={Picture8} class="d-block w-100" alt="..."/>
                    </div>
                    <div class="carousel-item" data-bs-interval="4000">
                        <img src={Picture9} class="d-block w-100" alt="..."/>
                    </div>
                    <div class="carousel-item" data-bs-interval="4000">
                        <img src={Picture10} class="d-block w-100" alt="..."/>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    )
}

export default Cards;